:root {
  /* Colors - Main */
  --atom-red: #ff4495;
  --atom-green: #52e3c2;
  --atom-blue: #0781ff;
  --atom-purple: #d211fe;
  --atom-bright-orange: #ff4b12;
  --atom-yellow: #ffd900;
  --atom-orange: #ed8a19;
  --atom-light-blue: #40c4ff;
  --atom-gray: #546e7a;
  --atom-gold: #efb068;
  --atom-light-gold: #ffebb7;
  --atom-shadow: rgba(10, 10, 13, 0.65);

  /* Dark Theme - Main */
  --atom-brand-0: #1a1a21;
  --atom-brand-1: #282833;
  --atom-brand-2: #282832;
  --atom-brand-3: #32323e;
  --atom-brand-4: #3f3f4a;
  --atom-brand-5: #3f3f4a;
  --atom-brand-6: #7a7a82;
  --atom-brand-7: #99999f;
  --atom-brand-8: #99999f;
  --atom-contrast: #ffffff;

  --atom-background: #282833;
  --atom-green-hover: #48ac99;
  --atom-light-text: #99999f;
  --atom-dark-text: #7a7a82;

  /* Dedicated dark colors */
  --atom-dark-red: #ff4495;
  --atom-dark-green: #52e3c2;
  --atom-dark-blue: #0781ff;
  --atom-dark-purple: #d211fe;
  --atom-dark-bright-orange: #ff4b12;
  --atom-dark-yellow: #ffd900;
  --atom-dark-orange: #ed8a19;
  --atom-dark-light-blue: #40c4ff;
  --atom-dark-gray: #546e7a;
  --atom-dark-gold: #efb068;
  --atom-dark-light-gold: #ffebb7;

  --atom-dark-brand-0: #1a1a21;
  --atom-dark-brand-1: #282833;
  --atom-dark-brand-2: #282832;
  --atom-dark-brand-3: #32323e;
  --atom-dark-brand-4: #3f3f4a;
  --atom-dark-brand-5: #3f3f4a;
  --atom-dark-brand-6: #7a7a82;
  --atom-dark-brand-7: #99999f;
  --atom-dark-brand-8: #99999f;
  --atom-dark-contrast: #ffffff;

  --atom-dark-green-hover: #48ac99;
  --atom-input: var(--atom-brand-4);

  /* Dark Theme - Main 2*/
  /* --atom-brand-0: #1a1a21;
    --atom-brand-1: #393945;
    --atom-brand-2: #282833;
    --atom-brand-3: #32323e;
    --atom-brand-4: #40424f;
    --atom-brand-5: #4d505f;
    --atom-brand-6: #6e7288;
    --atom-brand-7: #8f94ab;
    --atom-brand-8: #b4b8cd;
    --atom-contrast: #ffffff; */

  /* Colors- Alt */
  /* --atom-green:#00ffaa;
  --atom-red:#ff0c0c;
  --atom-blue:#0781ff;
  --atom-purple:#d211fe;
  --atom-bright-orange:#ff4b12;
  --atom-orange:#ed8a19;
  --atom-light-blue:#40c4ff;
  --atom-gray:#546e7a; */

  /* Dark Theme - Alt */
  /* --atom-brand-1:#000000;
  --atom-brand-2:#111111;
  --atom-brand-3:#1a1a1a;
  --atom-brand-4:#2a2a2a;
  --atom-brand-5:#3a3a3a;
  --atom-brand-6:#4a4a4a;
  --atom-brand-8:#aaaaaa;
  --atom-contrast:#ffffff; */

  /* Light Theme */
  /* --atom-brand-1:#FFFFFF;
  --atom-brand-2:#F5F7FC;
  --atom-brand-3:#FFFFFF;
  --atom-brand-4:#EEEFF6;
  --atom-brand-5:#EBECF1;
  --atom-brand-8:#32323E;
  --atom-contrast: #32323E; */

  /* --universal-border: 10px; */

  /* Colors - Vintage */
  /* --atom-red:#f50303;
    --atom-green:#1eb600;
    --atom-blue:#0781ff;
    --atom-purple:#d211fe;
    --atom-bright-orange:#ff4b12;
    --atom-orange:#ed8a19;
    --atom-light-blue:#40c4ff;
    --atom-gray:#546e7a; */

  /* Dark Theme - Vintage */
  /* --atom-brand-1:#111111;
    --atom-brand-2:#202020;
    --atom-brand-3:#303030;
    --atom-brand-4:#3f3f3f;
    --atom-brand-5:#474747;
    --atom-brand-6:#5f5f5f;
    --atom-brand-8:#f5a226;
    --atom-contrast:#ffffff; */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.no-theme:not(.themed) {
  --atom-red: #f27362;
  --atom-green: #52e3c2;
  --atom-blue: #0781ff;
  --atom-purple: #d211fe;
  --atom-bright-orange: #ff4b12;
  --atom-yellow: #ffd900;
  --atom-orange: #ed8a19;
  --atom-light-blue: #40c4ff;
  --atom-gray: #546e7a;
  --atom-gold: #efb068;
  --atom-light-gold: #ffebb7;

  --atom-brand-0: #1a1a21;
  --atom-brand-1: #282833;
  --atom-brand-2: #32323e;
  --atom-brand-3: #393945;
  --atom-brand-4: #40424f;
  --atom-brand-5: #4d505f;
  --atom-brand-6: #6e7288;
  --atom-brand-7: #8f94ab;
  --atom-brand-8: #b4b8cd;
  --atom-contrast: #ffffff;

  --atom-green-hover: #48ac99;
  --atom-input: #3f3f4a;
  color: var(--atom-contrast);
}

.jm-test-class {
  font-size: 10;
}

.atom-green {
  color: var(--atom-green);
}

.ios-momentum-scrolling {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.atom-blue {
  color: var(--atom-blue);
}

.grecaptcha-badge {
  visibility: hidden;
}

.bg-atom-green {
  background-color: var(--atom-green);
}

.atom-red {
  color: var(--atom-red);
}

.atom-light-text {
  color: var(--atom-light-text);
}

.bg-atom-red {
  background-color: var(--atom-red);
}

.atom-orange {
  color: var(--atom-orange);
}

.bg-atom-orange {
  background-color: var(--atom-orange);
}

.atom-yellow {
  color: var(--atom-yellow);
}

.bg-atom-yellow {
  background-color: var(--atom-yellow);
}

.atom-bright-orange {
  color: var(--atom-bright-orange);
}

.bg-atom-bright-orange {
  background-color: var(--atom-bright-orange);
}

.atom-light-gray {
  color: var(--atom-light-gray);
}

.bg-atom-light-gray {
  background-color: var(--atom-light-gray);
}

.atom-brand-1 {
  color: var(--atom-brand-1);
}

.bg-atom-brand-1 {
  background-color: var(--atom-brand-1);
}

.atom-brand-2 {
  color: var(--atom-brand-2);
}

.bg-atom-brand-2 {
  background-color: var(--atom-brand-2);
}

.atom-brand-6 {
  color: var(--atom-brand-6);
}

.bg-atom-brand-6 {
  background-color: var(--atom-brand-6);
}

.bd-atom-brand-6 {
  border-color: var(--atom-brand-6);
}

.border-atom-brand-6 {
  border: 2px solid var(--atom-brand-6);
}

.atom-brand-7 {
  color: var(--atom-brand-7);
}

.bg-atom-brand-7 {
  background-color: var(--atom-brand-7);
}

.bd-atom-brand-7 {
  border-color: var(--atom-brand-7);
}

.border-atom-brand-7 {
  border: 2px solid var(--atom-brand-7);
}

.atom-brand-8 {
  color: var(--atom-brand-8);
}

.bg-atom-brand-8 {
  background-color: var(--atom-brand-8);
}

.bd-atom-brand-8 {
  border-color: var(--atom-brand-8);
}

.border-atom-brand-8 {
  border: 2px solid var(--atom-brand-8);
}

.atom-brand-3 {
  color: var(--atom-brand-3);
}

.bg-atom-brand-3 {
  background-color: var(--atom-brand-3);
}

.atom-brand-4 {
  color: var(--atom-brand-4);
}

.bg-atom-brand-4 {
  background-color: var(--atom-brand-4);
}

.border-atom-brand-4 {
  border: 2px solid var(--atom-brand-4);
}

.atom-brand-5 {
  color: var(--atom-brand-5);
}

.bg-atom-brand-5 {
  background-color: var(--atom-brand-5);
}

.border-atom-brand-5 {
  border: 2px solid var(--atom-brand-5);
}

.atom-brand-1 {
  color: var(--atom-brand-1);
}

.bg-atom-brand-1 {
  background-color: var(--atom-brand-1);
}

.atom-contrast {
  color: var(--atom-contrast);
}

.font-din {
  font-family: 'DIN-Regular', 'GT America', sans-serif;
}

.vertical-center {
  vertical-align: middle;
}

.ag-header-cell-label {
  color: var(--atom-green);
  margin-bottom: 10px;
}

.xray-is-sub > .select-control__option {
  padding-left: 24px !important;
}

.hide-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.white-override {
  color: white !important;
}

.pop-large {
  box-shadow: 0px 6px 15px var(--atom-shadow);
  /*box-shadow:  0 6px 15px rgb(40 40 51 / 80%);*/
}

.pop-medium {
  box-shadow: 0px 2px 7px 2px var(--atom-shadow);
  /*box-shadow: 0 2px 7px 2px rgb(16 16 20 / 30%);*/
}

.pop-small {
  /*default*/
  /* box-shadow: 0px 2px 5px 2px rgba(36, 36, 46, 0.3); */
  box-shadow: 0px 4px 15px rgba(40, 40, 51, 0.6);

  /* box-shadow: 0px 2px 7px 2px rgba(16, 16, 20, 0.3); */
}

.atom-shadow {
  -moz-box-shadow: 3px 3px 20px -2px var(--atom-shadow);
  -webkit-box-shadow: 3px 3px 20px -2px var(--atom-shadow);
  box-shadow: 3px 3px 20px -2px var(--atom-shadow);
}

.padding-adapt {
  padding: 1.5rem 2.5rem;
}

.bg-trans-blur {
  background-color: rgba(80, 80, 82, 0.5);
  backdrop-filter: blur(8px);
}

.bg-trans-blur-light {
  background-color: rgb(200, 200, 210, 0.5);
  backdrop-filter: blur(8px);
}

.bg-trans-blur-dark {
  background-color: rgb(26, 26, 33, 0.5);
  backdrop-filter: blur(8px);
}

@font-face {
  font-family: 'DIN-Regular';
  src: url('./styles/fonts/D-DIN.eot');
  src: url('./styles/fonts/D-DIN.eot?#iefix') format('embedded-opentype'),
    url('./styles/fonts/D-DIN.woff2') format('woff2'),
    url('./styles/fonts/D-DIN.woff') format('woff'),
    url('./styles/fonts/D-DIN.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN-Regular';
  src: url('./styles/fonts/D-DIN-Bold.eot');
  src: url('./styles/fonts/D-DIN-Bold.eot?#iefix') format('embedded-opentype'),
    url('./styles/fonts/D-DIN-Bold.woff2') format('woff2'),
    url('./styles/fonts/D-DIN-Bold.woff') format('woff'),
    url('./styles/fonts/D-DIN-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('./styles/fonts/GT-America-Standard-Regular.eot');
  src: url('./styles/fonts/GT-America-Standard-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./styles/fonts/GT-America-Standard-Regular.woff2') format('woff2'),
    url('./styles/fonts/GT-America-Standard-Regular.woff') format('woff'),
    url('./styles/fonts/GT-America-Standard-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('./styles/fonts/GT-America-Standard-Bold.eot');
  src: url('./styles/fonts/GT-America-Standard-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./styles/fonts/GT-America-Standard-Bold.woff2') format('woff2'),
    url('./styles/fonts/GT-America-Standard-Bold.woff') format('woff'),
    url('./styles/fonts/GT-America-Standard-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('./styles/fonts/GT-America-Standard-Light.eot');
  src: url('./styles/fonts/GT-America-Standard-Light.eot?#iefix')
      format('embedded-opentype'),
    url('./styles/fonts/GT-America-Standard-Light.woff2') format('woff2'),
    url('./styles/fonts/GT-America-Standard-Light.woff') format('woff'),
    url('./styles/fonts/GT-America-Standard-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@tailwind preflight;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  overflow-y: visible;
}

body {
  background-color: var(--atom-background);
  color: var(--atom-contrast);
  font-family: 'DIN-Regular', 'GT America', 'system-ui', 'BlinkMacSystemFont',
    '-apple-system', 'Segoe UI', 'Helvetica Neue', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* By default, remove rounded corners on inputs for Safari */
input[type='text'] {
  border-radius: 0;
  -webkit-appearance: none;
  line-height: normal;
}

input:focus,
button:focus {
  outline: 0;
}

a {
  color: var(--atom-green);
  text-decoration: none;
}

a.anchor-hidden {
  height: 1px;
  visibility: hidden;
  overflow: hidden;
}

/* Animations for real-time price update fades */
@keyframes redFade {
  from {
    color: var(--atom-brand-0);
    background-color: var(--atom-red);
    border-radius: 25%;
    font-weight: bold;
  }
  to {
    color: inherit;
    background: transparent;
    font-weight: inherit;
  }
}

@keyframes greenFade {
  from {
    color: var(--atom-brand-0);
    background-color: var(--atom-green);
    border-radius: 25%;
    font-weight: bold;
  }
  to {
    color: inherit;
    background: transparent;
    font-weight: inherit;
  }
}
.carousel .slide {
  background-color: var(--atom-background-dark);
}

.redFade {
  animation-name: redFade;
  animation-duration: 1s;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s linear;
}
.atomHidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s linear;
}

.greenFade {
  animation-name: greenFade;
  animation-duration: 1s;
}

.ReactModalPortal {
  overflow-y: scroll;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

/* Animate React-Modal overlay */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.1s ease-out;
  z-index: 10;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

/* .ReactModal__Overlay includes the modal content inside */
/* Show the modal content 0.2s after the overlay fades in */
.ReactModal__Overlay .ReactModal__Content {
  opacity: 0;
  transition: opacity 0.1s ease-out;
  transition-delay: 0.1s;
}

.ReactModal__Overlay--after-open .ReactModal__Content {
  opacity: 1;
}

/* Fade out modal */
.ReactModal__Overlay--before-close {
  opacity: 0;
}

.HoverGreen:hover {
  color: var(--atom-green);
}

.transition-smooth {
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.dropdown-options.atom-show {
  overflow-y: hidden;
}

.dropdown-options {
  -webkit-transiton: height ease-out 0.2s;
  -moz-transition: height ease-out 0.2s;
  -ms-transition: height ease-out 0.2s;
  -o-transition: height ease-out 0.2s;
  transition: height ease-out 0.2s;

  height: 0px;
  margin: 0 auto 0 auto;
  overflow: hidden;
}

.trackingPopup {
  position: absolute;
  top: 50px;
  font-size: 26px;
  background-color: var(--atom-brand-2);
  border-radius: 8px;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
  width: 90%;
  z-index: 10000000;
  text-align: center;
  left: 0;
  right: 0;
}

.atomFadeOut {
  animation: fadeout ease-in-out 5s 1;
  -webkit-animation: fadeout ease-in-out 5s 1;
  opacity: 0;
}

.atomSlowFadeOut {
  animation: fadeout cubic-bezier(0.5, 0, 0.99, 1) 10s 1;
  -webkit-animation: fadeout cubic-bezier(0.5, 0, 0.99, 1) 10s 1;
  opacity: 0;
}

.atomFadeOutAbruptly {
  animation: fadeout cubic-bezier(0.5, 0, 1, 1) 1s 1;
  -webkit-animation: fadeout cubic-bezier(0.5, 0, 1, 1) 1s 1;
  opacity: 0;
}

.react-tagsinput-input {
  width: 500px;
}

.react-datepicker__input-container input {
  background-color: var(--atom-brand-5);
  border-radius: 10px;
  width: 130px;
  color: var(--atom-contrast);
  cursor: pointer;
  padding: 4px 4px 4px 16px;
}

.react-datepicker__input-container::after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f0d7';
  position: absolute;
  left: 110px;
  top: 2px;
  color: var(--atom-contrast);
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.no-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.atom-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.atom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.atom-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--atom-brand-4);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.atom-slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: var(--atom-contrast);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .atom-slider:before {
  background-color: var(--atom-green);
}

input:focus + .atom-slider {
  box-shadow: 0 0 1px var(--atom-var-4);
}

input:checked + .atom-slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.atom-slider.round {
  border-radius: 24px;
}

.atom-slider.round:before {
  border-radius: 50%;
}

.slider {
  background-color: var(--atom-background-dark);
}

/* -- SANDBOX -- */

.responsiveContainer {
  height: auto;
  /*background-color: rebeccapurple;*/
}
.responsiveChild {
  width: 100%;
  height: auto;
  min-height: 20rem;
}
@media (min-width: 1250px) {
  .responsiveContainer {
    display: flex;
    justify-content: space-between;
  }
  .responsiveChild {
    float: left;
    width: calc(33.333333% - 10px);
    /*background-color: yellow;*/
  }
}

.capitalization-container {
  height: 100%;
  width: 100%;
  font-size: 0.95rem;
}

.capitalization-container .bright-text {
  color: var(--atom-contrast);
}

.capitalization-container .editable {
  cursor: pointer;
}

.capitalization-container .consensus {
  color: var(--atom-green);
}

.capitalization-container .override {
  color: var(--atom-light-blue);
}

.capitalization-container .adjusted {
  color: var(--atom-orange);
}

.capitalization-container .right {
  text-align: right;
}

.capitalization-container .ag-react-container {
  width: 100%;
}

.capitalization-container .ag-row .ag-cell {
  width: 100%;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  margin-top: 10px;
  justify-content: center;
  display: inline-block;
}

.capitalization-container .ag-header {
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  padding-top: 10px;
  max-height: 20px;
}

.capitalization-container .ag-row {
  border-bottom-width: 1px;
  border-color: var(--atom-brand-4);
}

.capitalization-container .ag-center-cols-container :nth-child(2) {
  border-color: var(--atom-brand-6);
}
.capitalization-container .ag-center-cols-container :nth-child(4) {
  border-color: var(--atom-brand-6);
}

.capitalization-container .ag-center-cols-container :nth-child(6) {
  border-bottom-width: 0;
}
.capitalization-container .ag-center-cols-container :nth-child(6) {
  font-size: 0.85rem;
}
.capitalization-container
  .ag-center-cols-container
  :nth-child(6)
  .ag-cell
  :first-child {
  padding-left: 5px;
}

.valuation-container {
  font-size: 0.9rem;
  overflow: hidden;
}
.valuation-container .bright-text {
  color: var(--atom-contrast);
}
.valuation-container .imputed {
  /*color: var(--atom-blue)*/
  color: var(--atom-contrast);
}
.valuation-container .consensus {
  color: var(--atom-green);
}
.valuation-container .right {
  text-align: right;
}
.valuation-container .adjusted {
  color: var(--atom-orange);
}
.valuation-container .ag-react-container {
  width: 100%;
}
.valuation-container .ag-row .ag-cell {
  width: 100%;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  margin-top: 10px;
  justify-content: center;
  display: inline-block;
}
.valuation-container .ag-header {
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  padding-top: 10px;
  max-height: 20px;
}
.valuation-container .ag-row {
  border-bottom-width: 1px;
  border-color: var(--atom-brand-4);
}

.guidance-container {
  overflow: hidden;
  width: 100%;
}
.guidance-container .ag-center-cols-viewport {
  display: flex;
  flex-grow: 1;
  justify-content: right;
  text-align: right;
  flex-direction: row-reverse;
  width: 100%;
}

.guidance-container .ag-header-viewport {
  display: flex;
  justify-content: right;
  flex-direction: row-reverse;
  width: 100%;
}

.guidance-container .ag-react-container {
  width: 100%;
  font-size: 12px;
}

.guidance-container .ag-row .ag-cell {
  flex: 1;
  flex-grow: 1;
  font-size: 12px;
}

.guidance-container .ag-header-cell-label {
  color: var(--atom-contrast);
  font-weight: bold;
  border-bottom-width: 2px;
  font-size: 12px;
  margin-bottom: 0px;
  padding-bottom: 7px;
  border-color: var(--atom-brand-4);
  flex-direction: row-reverse;
}

.guidance-label-col-header {
  padding-top: 13px;
}

.guidance-label-col {
  text-align: left;
}

.guidance-container .ag-row {
  justify-content: right;
  color: var(--atom-contrast);
  font-size: 12px;
  margin-top: 0;
}

.guidance-container .ag-header-group-cell {
  border-bottom-width: 2px;
  border-bottom-color: var(--atom-brand-4);
}

.guidance-container .prior-subheader {
  border-right-width: 2px;
  padding-right: 5px;
  border-color: var(--atom-brand-4);
}

.guidance-container .prior-column {
  border-right-width: 2px;
  padding-right: 5px;
  border-color: var(--atom-brand-4);
}

.sandbox-grid-container {
  height: 100%;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 5rem;
  font-size: 0.95rem;
}

.sandbox-grid-container .bright-text {
  color: var(--atom-contrast);
}
.sandbox-grid-container .historical {
  color: inherit;
}
.sandbox-grid-container .consensus {
  color: var(--atom-green);
}
.sandbox-grid-container .imputed {
  color: var(--atom-contrast);
}
.sandbox-grid-container .override {
  color: var(--atom-light-blue);
}
.sandbox-grid-container .adjusted {
  color: var(--atom-orange);
}
.sandbox-grid-container .editable {
  cursor: pointer;
}
.sandbox-grid-container .right {
  text-align: right;
}

.sandbox-grid-container .bold-label {
  font-weight: bold;
}
.sandbox-grid-container .ag-react-container {
  width: 100%;
}
.sandbox-grid-container .ag-row .ag-cell {
  width: 100%;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  margin-top: 10px;
  justify-content: center;
  display: inline-block;
}
.sandbox-grid-container .ag-header-cell-text {
  display: inline-block;
  color: var(--atom-green);
  font-weight: bold;
}
.sandbox-grid-container .ag-header {
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  padding-top: 10px;
  max-height: 20px;
}
.sandbox-grid-container .ag-header-row .ag-header-cell:first-child {
  padding-left: 10px;
}

.sandbox-grid-container .ag-row .ag-cell:first-child {
  padding-left: 10px;
}

.sandbox-grid-container .ag-row {
  border-bottom-width: 2px;
  border-color: var(--atom-brand-4);
}

.sandbox-grid-container .ag-body-viewport {
  overflow-y: hidden;
}

/* Pined Left */
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(2) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(4) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(6) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(7) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(9) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(10) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(14) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(15) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(17) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(19) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(20) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(25) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(27) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(28) .ag-cell,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(31) .ag-cell,
/* Main Body*/
.sandbox-grid-container .ag-center-cols-container :nth-child(2) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(4) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(6) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(7) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(9) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(10) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(14) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(15) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(17) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(19) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(20) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(25) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(27) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(28) .ag-cell,
.sandbox-grid-container .ag-center-cols-container :nth-child(31) .ag-cell {
  font-size: 0.85rem;
}

/* Pinned Left */
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(2) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(4) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(6) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(7) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(9) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(10) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(14) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(15) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(17) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(19) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(20) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(25) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(27) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(28) .ag-cell :first-child,
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(31) .ag-cell :first-child,
/* Main Body */
.sandbox-grid-container .ag-center-cols-container :nth-child(2) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(4) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(6) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(7) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(9) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(10) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(14) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(15) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(17) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(19) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(20) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(25) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(27) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(28) .ag-cell :first-child,
.sandbox-grid-container .ag-center-cols-container :nth-child(31) .ag-cell :first-child {
  padding-left: 5px;
}

/* Pinned Left */
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(1),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(3),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(5),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(8),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(13),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(16),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(18),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(22),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(23),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(24),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(25),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(29),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(30),
/* Main body*/
.sandbox-grid-container .ag-center-cols-container :nth-child(1),
.sandbox-grid-container .ag-center-cols-container :nth-child(3),
.sandbox-grid-container .ag-center-cols-container :nth-child(5),
.sandbox-grid-container .ag-center-cols-container :nth-child(8),
.sandbox-grid-container .ag-center-cols-container :nth-child(13),
.sandbox-grid-container .ag-center-cols-container :nth-child(16),
.sandbox-grid-container .ag-center-cols-container :nth-child(18),
.sandbox-grid-container .ag-center-cols-container :nth-child(22),
.sandbox-grid-container .ag-center-cols-container :nth-child(23),
.sandbox-grid-container .ag-center-cols-container :nth-child(24),
.sandbox-grid-container .ag-center-cols-container :nth-child(25),
.sandbox-grid-container .ag-center-cols-container :nth-child(29),
.sandbox-grid-container .ag-center-cols-container :nth-child(30) {
  border-color: var(--atom-brand-5);
}

/* Pinned Left */
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(11),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(12),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(20),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(21),
.sandbox-grid-container .ag-pinned-left-cols-container :nth-child(31),
/* Main body*/
.sandbox-grid-container .ag-center-cols-container :nth-child(11),
.sandbox-grid-container .ag-center-cols-container :nth-child(12),
.sandbox-grid-container .ag-center-cols-container :nth-child(20),
.sandbox-grid-container .ag-center-cols-container :nth-child(21),
.sandbox-grid-container .ag-center-cols-container :nth-child(31) {
  border-bottom-width: 0;
}

.cell-editor-input {
  margin-right: 5px;
  color: white;
  border-radius: 3px;
  height: 1.5rem;
  background-color: var(--atom-brand-4);
  border: 1px solid var(--atom-green) !important;
  transition: 0.3s;
}

.cell-editor-input-error {
  border: 1px solid var(--atom-red) !important;
}

/* -- SANDBOX END -- */
.briefingModalStory {
  background-color: transparent !important;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

@keyframes lightbluefade {
  from {
    background: var(--atom-light-blue);
  }
  to {
    background: transparent;
  }
}

.fadeblue {
  animation-name: lightbluefade;
  animation-duration: 3s;
}

.url-linkified {
  color: var(--atom-light-blue);
}

.url-linkified:hover {
  text-decoration: underline;
}

.mention-linkified {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.mention-linkified:hover {
  color: var(--atom-brand-8);
}

.symbol-linkified {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.symbol-linkified:hover {
  color: var(--atom-brand-8);
}

.chat__mentions {
  margin: 1em 0;
}

.chat__mentions--singleLine .chat__mentions__control {
  display: inline-block;
  width: 130px;
}
.chat__mentions--singleLine .chat__mentions__highlighter {
  /* padding: 1px;
  border: 2px inset transparent; */
  min-height: auto !important;
}

.chat__mentions--multiLine .chat__mentions__highlighter {
  min-height: auto !important;
}

.chat__mentions--singleLine .chat__mentions__input {
  padding: 1px;
  border: 2px inset;
}

.chat__mentions__suggestions {
  position: absolute !important;
  bottom: 57px;
  top: auto !important;
  width: calc(100% + 240px);
  background-color: var(--atom-brand-4) !important;
  left: -32px;
}

.chat__mentions--multiLine .chat__mentions__control {
  font-size: 14pt;
}
.chat__mentions--multiLine .chat__mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 63px;
}
.chat__mentions--multiLine .chat__mentions__input {
  background-color: var(--atom-brand-5) !important;
  outline: none;
  flex-grow: 10;
  color: var(--atom-contrast);
  border-radius: 0.33rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 1rem;
  line-height: 1.25;
  font-size: 16px !important;
}

.chat__mentions--multiLine {
  margin: 0 1rem 0 0 !important;
}

.chat__mentions__suggestions__list {
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.chat__mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  background-color: var(--atom-brand-3);
}

.chat__mentions__suggestions__item--focused {
  background-color: var(--atom-brand-5);
}

.chat__mentions__mention {
  position: relative;
  z-index: 1;
  text-decoration: underline;
  pointer-events: none;
}

.atom-highlight {
  background-color: var(--atom-yellow);
  color: var(--atom-brand-2);
  border-radius: 4px;
  margin: 2px;
}

.select-control__option--is-selected {
  /* background-color: var(--atom-brand-5) !important; */
}

/* Prevent the appsflyer banner from blocking sign up */
#af-smart-banner {
  z-index: 2 !important;
}

.st1 {
  fill: var(--atom-brand-6);
}

.__react_component_tooltip {
  background-color: var(--atom-contrast) !important;
  color: var(--atom-brand-3);
  z-index: 999999;
}

.__react_component_tooltip.place-left:after,
.__react_component_tooltip.place-left:before {
  border-left-color: var(--atom-contrast) !important;
  border-right-color: var(--atom-contrast) !important;
}

.__react_component_tooltip.place-right:after,
.__react_component_tooltip.place-right:before {
  border-left-color: var(--atom-contrast) !important;
  border-right-color: var(--atom-contrast) !important;
}

.__react_component_tooltip.place-top:after,
.__react_component_tooltip.place-top:before {
  border-top-color: var(--atom-contrast) !important;
  border-bottom-color: var(--atom-contrast) !important;
}

.__react_component_tooltip.place-bottom:after,
.__react_component_tooltip.place-bottom:before {
  border-top-color: var(--atom-contrast) !important;
  border-bottom-color: var(--atom-contrast) !important;
}

.__react_component_tooltip.show {
  opacity: 1;
}

.__react_component_tooltip.type-light {
  background-color: var(--atom-contrast) !important;
  color: var(--atom-brand-3);
}

.pill {
  color: var(--atom-contrast);
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'DIN-Regular', 'GT America', sans-serif;
}

.select-control-container .select-control__multi-value__label {
  color: var(--atom-contrast);
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'DIN-Regular', 'GT America', sans-serif;
}

.select-control__multi-value__remove {
  color: var(--atom-brand-7);
}

.select-control__control.select-control__control--is-focused {
  box-shadow: none;
}

input::placeholder {
  color: var(--atom-light-text);
  opacity: 1;
}

.alertSearches::-webkit-scrollbar {
  -webkit-appearance: none;
}

.alertSearches::-webkit-scrollbar:vertical {
  width: 11px;
}

.alertSearches::-webkit-scrollbar:horizontal {
  height: 11px;
}

.alertSearches::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: var(
    --atom-brand-3
  ); /* should match background, can't be transparent */
  background-color: var(--atom-brand-7);
}

::-webkit-scrollbar-corner {
  background: var(--atom-brand-3);
}

.braintree-input-class {
  color: var(--atom-contrast);
  font-size: 16px;
  padding: 15px 22px;
}

.braintree-placeholder-class {
  color: var(--atom-contrast);
  opacity: 0.5;
}

.showAtomModal {
  opacity: 1;
  z-index: 2;
  animation: show 0.2s;

  transform: translate(-50%, -50%);
}
@keyframes show {
  from {
    transform: translate(-50%, 50%);
    opacity: 0;
    z-index: -1;
  }
  to {
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 2;
  }
}

.hideAtomModal {
  z-index: -1;
  opacity: 0;
  animation: hide 0.25s;

  transform: translate(-50%, 50%);
}
@keyframes hide {
  from {
    z-index: 2;
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  to {
    z-index: -1;
    transform: translate(-50%, 50%);
    opacity: 0;
  }
}
